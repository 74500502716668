"use client";

import { Loader, MantineProvider, Modal } from "@mantine/core";
import { SessionProvider } from "next-auth/react";
import { QueryClient, QueryClientProvider, isServer } from "@tanstack/react-query";
import { TooltipProvider } from "@/components/ui/tooltip";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { X } from "lucide-react";

import "mantine-react-table/styles.css";
import "@mantine/core/styles.css";
import "@mantine/core/styles.css";
import "@mantine/dropzone/styles.css";
import "@splidejs/react-splide/css";
import { CameraProvider } from "@/components/ui/camera/camera-provider";

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY!);

type Props = {
  children?: React.ReactNode;
};

function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 60 * 1000,
      },
    },
  });
}

let browserQueryClient: QueryClient | undefined = undefined;

export function getQueryClient() {
  if (isServer) {
    return makeQueryClient();
  } else {
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
}

export const queryClient = getQueryClient();

export const Providers = ({ children }: Props) => {
  return (
    <QueryClientProvider client={getQueryClient()}>
      <CameraProvider>
        <SessionProvider>
          <Elements stripe={stripePromise}>
            <MantineProvider
              defaultColorScheme="dark"
              forceColorScheme="dark"
              theme={{
                autoContrast: true,
                components: {
                  Loader: Loader.extend({
                    defaultProps: {
                      size: "xl",
                      type: "dots",
                    },
                  }),
                  Modal: Modal.extend({
                    defaultProps: {
                      padding: "p-3",
                      radius: "sm",
                      closeButtonProps: {
                        icon: <X className="w-4 h-4" />,
                        className:
                          "absolute right-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground",
                      },
                      overlayProps: {
                        className:
                          "fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
                      },
                      transitionProps: {
                        duration: 200,
                        transition: {
                          transitionProperty: "opacity, transform",
                          in: { opacity: 1, transform: "translate(-50%, -50%) scale(1)" },
                          out: { opacity: 0, transform: "translate(-50%, -50%) scale(0.95)" },
                        },
                      },
                    },
                    classNames: {
                      content:
                        "fixed left-[50%] overflow-auto max-h-screen top-[50%] z-50 grid   translate-x-[-50%] translate-y-[-50%] border bg-background shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg ",
                      header: "flex bg-background  min-h-[0px]  px-6 pt-6 pb-0 text-center sm:text-left",
                      title: "text-lg font-semibold leading-none tracking-tight",
                      body: "flex flex-col gap-4 p-6",
                      inner: "relative",
                    },
                  }),
                },
              }}
            >
              <TooltipProvider>{children}</TooltipProvider>
            </MantineProvider>
          </Elements>
        </SessionProvider>
      </CameraProvider>
    </QueryClientProvider>
  );
};
